import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import classnames from 'classnames'

// Components
import Layout from '../../../components/layout'
import Seo from '../../../components/Seo'
import Hero from '../../../components/cloud-studio/hero'
import Card from '../../../components/cloud-studio/card'
import SectionTitle from '../../../components/cloud-studio/section-title'

// Assets
import ArrowRight from '../../../images/cloud-studio/arrow-right.inline.svg'
import Button from '../../../components/cloud-studio/button'

function CloudApiPage() {
  const images = useStaticQuery(graphql`
    {
      cloudApiHero: file(
        relativePath: { eq: "cloud-studio/cloud-api-hero.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      cloudMetalCard: file(
        relativePath: { eq: "cloud-studio/cloud-metal-card-bg.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 270
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      cloudVpsCard: file(
        relativePath: { eq: "cloud-studio/cloud-vps-card-bg.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 270
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
    }
  `)

  return (
    <Layout
      mainClass={'bg-[#0D0E11] font-roobert'}
      headerVariant={'dark'}
      footerVariant={'dark'}
    >
      <Seo
        post={{
          seo: {
            title: 'Cloud Studio API | Liquid Web',
            metaDesc:
              'Get started with the powerful Cloud Studio API with helpful resources, tools, documentation, and tutorials on tokens, authentication, and more.',
          },
        }}
      />
      <Hero
        label="Cloud Studio API"
        title="A full-featured, project-ready API from day one"
        subtitle="Getting started with the Cloud Studio API is simple. We provide all the resources, tools, documentation, and tutorials developers need to take full advantage of our powerful platform."
        image={images?.cloudApiHero}
        imagePosition="right"
        primaryCta={{
          text: 'See documentation',
          url: 'https://api.liquidweb.com/docs',
          target: '_blank',
          variant: 'primary',
          as: 'button',
        }}
        secondaryCta={{
          text: 'Learn about Cloud Studio',
          url: '/products/cloud-hosting',
          variant: 'plain',
          as: 'link',
          icon: <ArrowRight />,
        }}
      />

      <div className="bg-lw-blue-dark py-24 mb-32">
        <div className="container">
          <div
            className={classnames(
              'flex',
              'items-start',
              'justify-between',
              'mb-12',
              'flex-col',
              'md:items-center',
              'md:flex-row'
            )}
          >
            <SectionTitle
              title="Knowledge Base resources"
              className="mb-4 lg:!mb-0"
            />
            <Button
              text="Browse Knowledge Base"
              url="/kb"
              variant="plain"
              icon={<ArrowRight />}
            />
          </div>
          <div className="grid gap-10 lg:grid-cols-3">
            <Card
              variant="light"
              label="Automation"
              title="Cloud Studio API: basic setup"
              description="Discover how Terraform can benefit you—follow along as we walk through the process of using IaC to automate deployment of a Cloud Studio server with a popular application."
              link={{
                url: '/kb/cloud-studio-deploy-wordpress/',
                text: 'Read article',
              }}
            />
            <Card
              variant="light"
              label="Collaboration"
              title="Liquid Web on GitHub"
              description="Get tools for working with our API, like a command-line interface utility and Golang API client, a Terraform provider, and more in our GitHub repositories."
              link={{
                url: 'https://github.com/liquidweb',
                text: 'Visit GitHub',
                target: '_blank',
              }}
            />
            <Card
              variant="light"
              label="Integration"
              title="How to set up and use the Liquid Web CLI interface"
              description="Learn about the convenience and advantages of the command-line interface for our API."
              link={{
                url: '/kb/using-the-liquid-web-command-line-interface-lw-cli/',
                text: 'Read article',
              }}
            />
          </div>
        </div>
      </div>

      <div className="container mb-32">
        <SectionTitle title="Our robust API will help you meet your specific infrastructure needs" />
        <div className="grid gap-10 lg:grid-cols-2">
          <Card
            variant="dark"
            label="Dedicated server"
            title="Cloud Metal"
            subtitle={
              <>
                Cloud flexibility meets bare metal power, starting at{' '}
                <span className="text-lw-white">$115 a month</span>
              </>
            }
            link={{
              url: '/products/cloud-hosting/bare-metal',
              text: 'Learn more',
            }}
            backgroundImage={images?.cloudMetalCard}
          />
          <Card
            variant="light"
            label="Flexible environment"
            title="Cloud VPS"
            subtitle={
              <>
                The perfect blend of scalability and affordability, starting at{' '}
                <span className="text-lw-black">$5 a month</span>
              </>
            }
            link={{ url: '/products/cloud-hosting/vps', text: 'Learn more' }}
            backgroundImage={images?.cloudVpsCard}
          />
        </div>
      </div>
    </Layout>
  )
}

export default CloudApiPage
